// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-case-studies-altor-employment-solicitors-js": () => import("./../../../src/pages/case-studies/altor-employment-solicitors.js" /* webpackChunkName: "component---src-pages-case-studies-altor-employment-solicitors-js" */),
  "component---src-pages-case-studies-bennison-brown-mortgages-js": () => import("./../../../src/pages/case-studies/bennison-brown-mortgages.js" /* webpackChunkName: "component---src-pages-case-studies-bennison-brown-mortgages-js" */),
  "component---src-pages-case-studies-henry-malcolm-js": () => import("./../../../src/pages/case-studies/henry-malcolm.js" /* webpackChunkName: "component---src-pages-case-studies-henry-malcolm-js" */),
  "component---src-pages-case-studies-industry-cv-js": () => import("./../../../src/pages/case-studies/industry-cv.js" /* webpackChunkName: "component---src-pages-case-studies-industry-cv-js" */),
  "component---src-pages-case-studies-rise-rinse-repeat-js": () => import("./../../../src/pages/case-studies/rise-rinse-repeat.js" /* webpackChunkName: "component---src-pages-case-studies-rise-rinse-repeat-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-services-facebook-ads-js": () => import("./../../../src/pages/services/facebook-ads.js" /* webpackChunkName: "component---src-pages-services-facebook-ads-js" */),
  "component---src-pages-services-google-ads-js": () => import("./../../../src/pages/services/google-ads.js" /* webpackChunkName: "component---src-pages-services-google-ads-js" */),
  "component---src-pages-services-landing-page-design-js": () => import("./../../../src/pages/services/landing-page-design.js" /* webpackChunkName: "component---src-pages-services-landing-page-design-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

